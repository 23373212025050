<template>
  <div class="container-fluid ">
    <div class="row card my-half">
      <div class="col-12 px-2 pt-half">
        <div class="d-flex flex-wrap">
          <dx-util-select-box
            v-model="selectedStore"
            :data-source="stores"
            display-expr="text"
            value-expr="value"
            @value-changed="searchByFilter"
          />
          <div style="padding-top: 2px; padding-bottom: 2px; margin-left: 4px;">
            <dx-util-button
              v-if="selectedPeriod === 'CUSTOM'"
              icon="icon bi-calendar-x"
              type="danger"
              @click="showPeriodSelection"
            />
          </div>
          <dx-util-date-box
            v-if="selectedPeriod === 'CUSTOM'"
            v-model="beginDate"
            class="mx-half"
            placeholder="Begin Date"
            :max="endDate"
            type="date"
            date-serialization-format="yyyy-MM-dd"
            @value-changed="onDateSelected"
          />
          <dx-util-date-box
            v-if="selectedPeriod === 'CUSTOM'"
            v-model="endDate"
            class="mx-half"
            placeholder="End Date"
            :min="beginDate"
            type="date"
            date-serialization-format="yyyy-MM-dd"
            @value-changed="onDateSelected"
          />
          <dx-util-select-box
            v-else
            v-model="selectedPeriod"
            :data-source="periods"
            display-expr="text"
            value-expr="value"
            width="200"
            class="ml-half"
            @value-changed="refreshBySelectedPeriod"
          />
          <dx-util-select-box
            v-model="selectedMarketplace"
            :data-source="marketplaces"
            display-expr="text"
            value-expr="value"
            width="200"
            class="ml-half"
            @value-changed="searchByFilter"
          />
          <dx-util-select-box
            v-model="selectedChannel"
            :data-source="fulfillmentMethods"
            display-expr="text"
            value-expr="value"
            width="200"
            class="ml-half"
            @value-changed="searchByFilter"
          />
          <div class="flex-grow-1"></div>
          <dx-util-text-box v-if="false" v-model="searchText" placeholder="Search..." />
        </div>
      </div>
      <div class="col-12 pb-3">
        <div class="w-100">
          <tiles :tiles-data="tilesData" />
        </div>
      </div>
    </div>
    <div class="row card my-half">
      <div class="col-12">
        <dx-util-tab-panel :animation-enabled="true" :defer-rendering="true" :show-nav-buttons="true" :selected-index="activeTabIndex" style="min-height: 52vh;">
          <dx-util-panel-item title="Overview" icon="bi bi-graph-up-arrow">
            <template #default>
              <dashboard-overview-chart :filters="filters" />
            </template>
          </dx-util-panel-item>
          <dx-util-panel-item :visible="showComparison" title="Comparison" icon="bi bi-graph-up-arrow">
            <template #default>
              <dashboard-comparison-chart :filters="filters" />
            </template>
          </dx-util-panel-item>
          <dx-util-panel-item title="Products" icon="bi bi-list-check">
            <template #default>
              <product-list :filters="filters" :date-period="datePeriod" />
            </template>
          </dx-util-panel-item>
        </dx-util-tab-panel>
      </div>
    </div>
  </div>
</template>

<script>
import GridBase from '@core/dev-extreme/mixins/grid/base'
import { getMarketplaceList } from '@/enums/marketplaceEnum'
import { getFulfillmentMethodList } from '@/enums/fulfillmentType.enum'
import moment from 'moment'
import dashboardPeriodTypesEnum, {
  getDashboardPeriodTypesEnum,
  getDashboardPeriodTypesEnumList,
} from '@/enums/dashboardPeriodTypes.enum'
import amazonReportService from '@/http/requests/reports/amazonReportService'
import AmazonSalesFilter from '@/http/models/search-filters/AmazonSalesFilter'
import { currencyFormatter } from '@core/utils/filter'
import DashboardOverviewChart from '@/views/apps/reports/amazon-reports/components/DashboardOverviewChart.vue'
import DashboardComparisonChart from '@/views/apps/reports/amazon-reports/components/DashboardComparisonChart.vue'
import ProductList from '@/views/apps/reports/amazon-reports/components/ProductList.vue'
import commonEntityService from '@/http/requests/common/commonEntityService'
import Tiles from './Tiles.vue'

export default {
  components: {
    Tiles,
    DashboardOverviewChart,
    DashboardComparisonChart,
    ProductList,
  },
  mixins: [GridBase],
  data() {
    return {
      showComparison: true,
      filters: {},
      selectedStore: 0,
      stores: [],
      selectedPeriod: dashboardPeriodTypesEnum.MONTHLY.value,
      periods: getDashboardPeriodTypesEnumList(),
      selectedChannel: 'ALL',
      selectedMarketplace: 'ALL',
      beginDate: null,
      endDate: null,
      searchText: '',
      customTileData: {},
      tilesData: [
        {
          id: this.$uid(),
          color: '#568BE4',
          title: 'Today',
          beginDate: '',
          endDate: '',
          visible: true,
          data: {
          },
        },
        {
          id: this.$uid(),
          color: '#0077b6',
          title: 'Yesterday',
          beginDate: '',
          endDate: '',
          visible: true,
          data: {
          },
        },
        {
          id: this.$uid(),
          color: '#34a0a4',
          title: `${this.selectedPeriod} to Date`,
          beginDate: '',
          endDate: '',
          visible: true,
          data: {
          },
        },
        {
          id: this.$uid(),
          color: '#417415',
          title: 'Last Month',
          beginDate: '',
          endDate: '',
          visible: true,
          data: {
          },
        },
        {
          id: this.$uid(),
          color: '#76c893',
          title: 'this month',
          beginDate: '',
          endDate: '',
          visible: false,
          data: {
          },
        },
      ],
      marketplaces: getMarketplaceList(),
      fulfillmentMethods: getFulfillmentMethodList(),
      activeTabIndex: 0,
    }
  },
  computed: {
    datePeriod() {
      return this.calculateDates(this.selectedPeriod, 'toDate')
    },
  },
  watch: {
    selectedPeriod: {
      immediate: true,
      deep: true,
      handler() {
        if (this.selectedPeriod === dashboardPeriodTypesEnum.CUSTOM.value) {
          this.$set(this.tilesData[0], 'title', 'Custom Dates')
          this.$set(this.tilesData[0], 'title', 'Custom Dates')
          this.$set(this.tilesData[1], 'visible', false)
          this.$set(this.tilesData[2], 'visible', false)
          this.$set(this.tilesData[3], 'visible', false)
        } else {
          this.$set(this.tilesData[1], 'visible', true)
          this.$set(this.tilesData[2], 'visible', true)
          this.$set(this.tilesData[3], 'visible', true)
        }

        const period = getDashboardPeriodTypesEnum(this.selectedPeriod)
        this.$set(this.tilesData[2], 'title', `${period?.text} to Date`)
        this.$set(this.tilesData[3], 'title', `Last ${period?.text}`)
        this.$set(this.tilesData[4], 'title', `This ${period?.text} Forecast`)

        const toDate = this.calculateDates(this.selectedPeriod, 'toDate')
        this.$set(this.tilesData[0], 'beginDate', toDate.today)
        this.$set(this.tilesData[0], 'endDate', toDate.today)
        this.$set(this.tilesData[1], 'beginDate', toDate.yesterday)
        this.$set(this.tilesData[1], 'endDate', toDate.yesterday)
        this.$set(this.tilesData[2], 'beginDate', toDate.beginDate)
        this.$set(this.tilesData[2], 'endDate', toDate.endDate)

        const last = this.calculateDates(this.selectedPeriod, 'last')
        this.$set(this.tilesData[3], 'beginDate', last.beginDate)
        this.$set(this.tilesData[3], 'endDate', last.endDate)

        const toEnd = this.calculateDates(this.selectedPeriod, 'toEnd')
        this.$set(this.tilesData[4], 'beginDate', toEnd.beginDate)
        this.$set(this.tilesData[4], 'endDate', toEnd.endDate)
        // To hide forecast tile
        // this.tilesData.splice(4)
      },
    },
  },
  async created() {
    await this.getStoresForCurrentCompany()
    await this.loadData()
  },
  methods: {
    getStoresForCurrentCompany() {
      commonEntityService.fetchStoresForCurrentCompany().then(result => {
        const data = result.data
        this.setStores(data)
      })
    },
    setStores(data) {
      this.stores.splice(0, this.stores.length)
      data.forEach(element => {
        this.stores.push({
          value: element.id,
          text: element.name,
          companyId: element.parentCompany,
        })
      })
      if (this.stores.length > 0) {
        this.selectedStore = this.stores[0].value
      }
    },
    async loadData() {
      AmazonSalesFilter.setDefaultFilters()
      AmazonSalesFilter.storeId = this.selectedStore
      AmazonSalesFilter.dashboardPeriodType = this.selectedPeriod
      AmazonSalesFilter.channel = this.selectedChannel
      AmazonSalesFilter.marketplace = this.selectedMarketplace
      AmazonSalesFilter.beginDate = this.beginDate
      AmazonSalesFilter.endDate = this.endDate
      this.filters = AmazonSalesFilter.getFilters()
      const response = await amazonReportService.dashboardDataByQuery(this.filters)
      this.setTilesData(response)
    },
    findMatchingObject(data, dashboardType, periodType) {
      return data.find(item => item?.dashboardType === dashboardType && item?.periodType === periodType)
    },
    refreshBySelectedPeriod() {
      if (this.selectedPeriod === dashboardPeriodTypesEnum.CUSTOM.value) {
        this.showComparison = false
        const today = new Date()
        this.beginDate = today.toDateString()
        this.endDate = today.toDateString()
        return
      }
      this.searchByFilter()
    },
    async searchByFilter() {
      // On store selection
      AmazonSalesFilter.storeId = this.selectedStore
      AmazonSalesFilter.channel = this.selectedChannel
      AmazonSalesFilter.marketplace = this.selectedMarketplace
      AmazonSalesFilter.dashboardPeriodType = this.selectedPeriod
      AmazonSalesFilter.beginDate = this.beginDate ? moment(this.beginDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : null
      AmazonSalesFilter.endDate = this.endDate ? moment(this.endDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : null
      this.filters = AmazonSalesFilter.getFilters()
      const response = await amazonReportService.dashboardDataByQuery(this.filters)
      this.setTilesData(response)
    },
    showPeriodSelection() {
      this.selectedPeriod = 'MONTHLY'
      this.showComparison = true
      this.beginDate = null
      this.endDate = null
      this.searchByFilter()
    },
    onDateSelected() {
      if (this.selectedPeriod === dashboardPeriodTypesEnum.CUSTOM.value) {
        const bd = moment(this.beginDate).format('YYYY-MM-DD HH:mm:ss')
        const ed = moment(this.endDate).format('YYYY-MM-DD HH:mm:ss')
        this.$set(this.tilesData[0], 'beginDate', bd)
        this.$set(this.tilesData[0], 'endDate', ed)
      }
      this.searchByFilter()
    },
    setTilesData(response) {
      this.$set(this.tilesData[0], 'data', this.findMatchingObject(response, 'TODAY', null))
      this.$set(this.tilesData[1], 'data', this.findMatchingObject(response, 'YESTERDAY', null))
      this.$set(this.tilesData[2], 'data', this.findMatchingObject(response, this.selectedPeriod, 'toDate'))
      this.$set(this.tilesData[3], 'data', this.findMatchingObject(response, this.selectedPeriod, 'last'))
      this.$set(this.tilesData[4], 'data', this.findMatchingObject(response, this.selectedPeriod, 'toEnd'))
      this.$set(this.tilesData[4], 'data', this.findMatchingObject(response, this.selectedPeriod, 'toEnd'))
      if (this.selectedPeriod === dashboardPeriodTypesEnum.CUSTOM.value) {
        this.$set(this.tilesData[0], 'data', this.findMatchingObject(response, this.selectedPeriod, null))
      }
    },
    calculateDates(period, option) {
      let beginDate; let
        endDate
      const currentDate = moment()

      const today = currentDate.format('YYYY-MM-DD HH:mm:ss')
      const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss')

      switch (period) {
        case 'WEEKLY':
          beginDate = moment(currentDate)
          endDate = moment(currentDate)
          if (option === 'toDate') {
            beginDate.startOf('isoWeek')
            endDate.endOf('day')
          } else if (option === 'toEnd') {
            beginDate.startOf('isoWeek')
            endDate.endOf('isoWeek')
          } else if (option === 'last') {
            beginDate.subtract(1, 'week').startOf('isoWeek')
            endDate.subtract(1, 'week').endOf('isoWeek')
          }
          break
        case 'MONTHLY':
          beginDate = moment(currentDate)
          endDate = moment(currentDate)
          if (option === 'toDate') {
            beginDate.startOf('month')
            endDate.endOf('day')
          } else if (option === 'toEnd') {
            beginDate.startOf('month')
            endDate.endOf('month')
          } else if (option === 'last') {
            beginDate.subtract(1, 'month').startOf('month')
            endDate.subtract(1, 'month').endOf('month')
          }
          break
        // case 'Quarter':
        //   beginDate = moment(currentDate)
        //   endDate = moment(currentDate)
        //   if (option === 'toDate') {
        //     beginDate.startOf('quarter')
        //     endDate.endOf('day')
        //   } else if (option === 'toEnd') {
        //     beginDate.startOf('quarter')
        //     endDate.endOf('quarter')
        //   } else if (option === 'last') {
        //     beginDate.subtract(1, 'quarter').startOf('quarter')
        //     endDate.subtract(1, 'quarter').endOf('quarter')
        //   }
        //   break
        case 'YEARLY':
          beginDate = moment(currentDate)
          endDate = moment(currentDate)
          if (option === 'toDate') {
            beginDate.startOf('year')
            endDate.endOf('day')
          } else if (option === 'toEnd') {
            beginDate.startOf('year')
            endDate.endOf('year')
          } else if (option === 'last') {
            beginDate.subtract(1, 'year').startOf('year')
            endDate.subtract(1, 'year').endOf('year')
          }
          break
        default:
          throw new Error('Invalid period provided')
      }
      return {
        today: today,
        yesterday: yesterday,
        beginDate: beginDate.format('YYYY-MM-DD HH:mm:ss'),
        endDate: endDate.format('YYYY-MM-DD HH:mm:ss'),
      }
    },
    openProductDetails(e) {
      this.selectedProductId = e.param
      this.isProductDetailsVisible = true
    },
    getCurrencyFormat(data) {
      return currencyFormatter(data)
    },
    resolveByValue(value) {
      const profit = parseInt(value, 10)
      if (profit >= 0) {
        return 'badge badge-light-success'
      }
      return 'badge badge-light-danger'
    },
  },
}
</script>

<style scoped>
</style>

<template>
  <div style="height:50vh;">
    <div class="d-flex flex-wrap p-half">
      <dx-util-button v-if="false" text="Sales" />
      <dx-util-button v-if="false" text="Expenses" />
      <dx-util-button v-if="false" text="Returns" />
      <dx-util-button v-if="false" text="Refunds" />
      <div class="flex-grow-1"></div>
      <div>
        <dx-util-button :type="selectedValue === 'Sales Amount' ? 'default' : ''" text="Sales Amount" @click="selectedValue = 'Sales Amount'" />
        <dx-util-button :type="selectedValue === 'Item Count' ? 'default' : ''" text="Item Count" @click="selectedValue = 'Item Count'" />
      </div>
    </div>
    <div class="px-1">
      <DxChart
        id="chart"
        :data-source="dataSource"
        :title="title"
      >
        <DxValueAxis position="left">
          <DxTitle text="$" />
        </DxValueAxis>
        <DxCommonSeriesSettings
          argument-field="title"
          type="bar"
          hover-mode="allArgumentPoints"
          selection-mode="allArgumentPoints"
        >
          <DxLabel :visible="true">
            <DxFormat
              :precision="0"
              type="fixedPoint"
            />
          </DxLabel>
        </DxCommonSeriesSettings>
        <DxSeries
          v-if="selectedValue === 'Sales Amount'"
          value-field="previousAmount"
          name="Previous"
          color="#e4a455"
        />
        <DxSeries
          v-if="selectedValue === 'Sales Amount'"
          value-field="currentAmount"
          name="Current"
          color="#3dbab2"
        />
        <DxSeries
          v-if="selectedValue === 'Item Count'"
          type="spline"
          value-field="previousQuantity"
          name="Previous"
          color="#e4a455"
        />
        <DxSeries
          v-if="selectedValue === 'Item Count'"
          type="spline"
          value-field="currentQuantity"
          name="Current"
          color="#3dbab2"
        />
        <DxLegend
          vertical-alignment="bottom"
          horizontal-alignment="center"
        />
        <DxExport :enabled="true" />
      </DxChart>
    </div>
  </div>
</template>

<script>
import {
  DxChart,
  DxSeries,
  DxCommonSeriesSettings,
  DxLabel,
  DxFormat,
  DxLegend,
  DxExport, DxValueAxis, DxTitle,
} from 'devextreme-vue/chart'
import dashboardPeriodTypesEnum from '@/enums/dashboardPeriodTypes.enum'
import amazonReportService from '@/http/requests/reports/amazonReportService'
import AmazonSalesFilter from '@/http/models/search-filters/AmazonSalesFilter'

export default {
  components: {
    DxTitle,
    DxValueAxis,
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxLabel,
    DxFormat,
    DxLegend,
    DxExport,
  },
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedContent: 'Sales',
      dataSource: [],
      selectedValue: 'Sales Amount',
    }
  },
  computed: {
    title() {
      let title = `${this.selectedContent} Comparison Chart (Previous Week vs. Current Week)`
      if (this.filters.dashboardPeriodType === dashboardPeriodTypesEnum.MONTHLY.value) {
        title = `${this.selectedContent} Comparison Chart (Previous Month vs. Current Month)`
      }
      if (this.filters.dashboardPeriodType === dashboardPeriodTypesEnum.YEARLY.value) {
        title = `${this.selectedContent} Comparison Chart (Previous Year vs. Current Year)`
      }
      return title
    },
  },
  watch: {
    filters: {
      immediate: true,
      deep: true,
      handler() {
        this.getComparisonChartData()
      },
    },
  },
  mounted() {
    this.getComparisonChartData()
  },
  methods: {
    async getComparisonChartData() {
      const filter = AmazonSalesFilter.getFilters()
      const response = await amazonReportService.dashboardComparison(filter)
      this.dataSource = this.transformSalesReports(response)
    },
    transformSalesReports(dataArray) {
      return dataArray.map(item => ({
        title: item.title,
        previousAmount: item?.proviousSalesReport?.price || 0,
        currentAmount: item?.currentSalesReport?.price || 0,
        previousQuantity: item?.proviousSalesReport?.quantity || 0,
        currentQuantity: item?.currentSalesReport?.quantity || 0,
      }))
    },
  },
}
</script>

<style lang="scss" scoped>
#chart {
  height: 45vh;
}
</style>

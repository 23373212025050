<template>
  <div class="d-flex flex-wrap justify-content-between">
    <div v-for="tile in data" :key="tile.id" class="flex-grow-1 mx-half">
      <report-tile
        :id="tile.id"
        :color="tile.color"
        :title="tile.title"
        :begin-date="tile.beginDate"
        :end-date="tile.endDate"
        :tile-data="tile.data"
      />
    </div>
  </div>
</template>

<script>
import ReportTile from '@/views/apps/reports/amazon-reports/ReportTile.vue'

export default {
  components: { ReportTile },
  props: {
    tilesData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data: [],
    }
  },
  watch: {
    tilesData: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.data = newValue.filter(el => el.visible === true)
      },
    },
  },
}
</script>

<style lang="scss" scoped>

</style>

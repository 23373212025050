<template>
  <div class="tile-card flex-grow-1 w-100 h-100">
    <div v-if="true" class="d-flex tile-header justify-content-between" :style="{backgroundColor: color}">
      <div class="">
        <div style="font-weight: 600;">
          {{ title }}
        </div>
        <div v-if="beginDate === endDate">
          {{ getDatePart(beginDate) }}
        </div>
        <div v-else>
          {{ getDatePart(beginDate) }} - {{ getDatePart(endDate) }}
        </div>
      </div>
      <div>
        <div class="pt-half">
          <p-icon :id="`salesReport-${id}`" role="button" name=" bi-list" />
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="px-1 py-half ">
        <div class="text-left">
          Total Sales
        </div>
        <div class="sales">
         {{ getCurrencyFormat(tileData.totalSales) || '-' }}
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between py-1 border-bottom">
      <div class="px-1 w-100">
        <div>Quantity</div>
        <div class="custom-number">
          {{ tileData.itemCount || '-' }}
        </div>
      </div>
      <div class="px-1 w-100">
        <div>COGS</div>
        <div class="custom-number">
          {{ getCurrencyFormat(tileData.totalCOGS) || '-' }}
        </div>
      </div>
      <div class="px-1 w-100">
        <div>Amazon Fees</div>
        <div class="custom-number">
          {{ getCurrencyFormat(tileData.amazonFees) || '-' }}
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between py-1 border-bottom">
      <div class="px-1 w-100">
        <div>
          Refunds
        </div>
        <div class="custom-number">
          {{ tileData.refund || '-' }}
        </div>
      </div>
      <div class="px-1 w-100">
        <div>
          Other Fees
        </div>
        <div class="custom-number">
          {{ getCurrencyFormat(tileData.otherCosts ) || '-' }}
        </div>
      </div>
      <div class="px-1 w-100">
        <div>
          Add.Expenses
        </div>
        <div class="custom-number">
          {{ getCurrencyFormat(tileData.expenseAmount ) || '-' }}
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between py-1 border-bottom">
      <div class="px-1 w-100">
        <div>
          Add.Income
        </div>
        <div class="custom-number">
          {{ getCurrencyFormat(tileData.incomeAmount ) || '-' }}
        </div>
      </div>
      <div class="px-1 w-100">
        <div>Gross Profit</div>
        <div class="custom-number">
          {{ getCurrencyFormat(tileData.profit) || '-' }}
        </div>
      </div>
      <div class="px-1 w-100 badge" style="margin-right: 2px" :class="tileData.netProfit > 0 ? 'badge-light-success' : 'badge-light-danger'">
        <div class="text-left">
          Net Profit
        </div>
        <div class="custom-number text-left">
          {{ getCurrencyFormat(tileData.netProfit) || '-' }}
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between py-1 border-bottom">
      <div class="px-1 w-100">
        <div>
          Pending Sales
        </div>
        <div class="custom-number">
          {{ getCurrencyFormat(tileData.pendingTotalSales ) || '-' }}
        </div>
      </div>
      <div class="px-1 w-100">
        <div>Pending Item Qty</div>
        <div class="custom-number">
          {{ tileData.pendingItemCount || '-' }}
        </div>
      </div>
      <div class="px-1 w-100 text-left" style="margin-right: 2px">
        <i v-b-tooltip.d100.hover.bottom.v-primary title="Pending sales are included in the total sales" class="bi bi-exclamation-circle-fill" />
      </div>
    </div>
    <div>
      <dx-util-popover :width="360" :visible="false"
        :target="`#salesReport-${id}`" position="bottom"
        show-event="dxhoverstart"
        hide-event="dxclick"
      >
        <table width="100%">
          <tr class="font-weight-bold" style="font-size: 16px;">
            <td>
              Sales
            </td>
            <td class="text-right">
              $ {{ tileData.totalSales || '-' }}
            </td>
          </tr>
          <tr class="font-weight-bold" style="font-size: 16px;">
            <td>Refund Cost</td>
            <td class="text-right">
              $ {{ tileData.refund || '-' }}
            </td>
          </tr>
          <tr class="font-weight-bold" style="font-size: 16px;">
            <td>Cost of Goods Sold (COGS)</td>
            <td class="text-right">
              $ {{ tileData.totalCOGS || 0 }}
            </td>
          </tr>
          <tr class="font-weight-bold" style="font-size: 16px;">
            <td>Amazon Fees</td>
            <td class="text-right">
              $ {{ tileData.amazonFees || 0 }}
            </td>
          </tr>
          <tr>
            <td class="">
              - Amazon Referral Fee
            </td>
            <td class="text-right">
              $ {{ tileData.referralFee || 0 }}
            </td>
          </tr>
          <tr>
            <td class="">
              - FBA Fee
            </td>
            <td class="text-right">
              $ {{ tileData.fbafee || 0 }}
            </td>
          </tr>
          <tr>
            <td class="">
              - MFN Shipping
            </td>
            <td class="text-right">
              $ {{ tileData.mfnShipping || 0 }}
            </td>
          </tr>
          <tr>
            <td class="">
              - Misc Fees
            </td>
            <td class="text-right">
              $ {{ tileData.miscFee || 0 }}
            </td>
          </tr>
          <tr class="font-weight-bold" style="font-size: 16px;">
            <td>Gross Profit</td>
            <td class="text-right">
              $ {{ tileData.profit || 0 }}
            </td>
          </tr>
          <tr class="font-weight-bold" style="font-size: 16px;">
            <td>Other Fees</td>
            <td class="text-right">
              $ {{ tileData.otherCosts || 0 }}
            </td>
          </tr>
          <tr>
            <td class="">
              - Prep Fee
            </td>
            <td class="text-right">
              $ {{ tileData.prepFee || 0 }}
            </td>
          </tr>
          <tr>
            <td class="">
              - Shipping Fee
            </td>
            <td class="text-right">
              $ {{ tileData.shippingFee || 0 }}
            </td>
          </tr>
          <tr class="font-weight-bold" style="font-size: 16px;">
            <td>Net Profit</td>
            <td class="text-right">
              $ {{ tileData.netProfit || 0 }}
            </td>
          </tr>
        </table>
      </dx-util-popover>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { currencyFormatter } from '@core/utils/filter'

export default {
  props: {
    color: {
      type: String,
      default: '#568BE4',
    },
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    beginDate: {
      type: String,
      default: '',
    },
    endDate: {
      type: String,
      default: '',
    },
    tileData: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    getDatePart(dateString) {
      const date = moment(dateString, 'YYYY-MM-DD HH:mm:ss')
      return date.format('YYYY-MM-DD')
    },
    getCurrencyFormat(data) {
      return currencyFormatter(data)
    },

  },
}
</script>

<style lang="scss" scoped>
.tile-card {
  margin: 4px;
  border: 1px solid rgb(220, 220, 220);
  border-radius: 3px;
}

.dark-layout .tile-card {
  margin: 4px;
  border: 1px solid rgb(72, 92, 105);
  border-radius: 3px;
}
.tile-header {
  background-color: rgb(64, 167, 240);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 6px 12px 6px 12px;
  color: white;
  font-size: 13px;
}
.sales {
  font-size: 24px;
  font-weight: 700;
  padding: 0;
  line-height: 1;
  color: black;
}
.dark-layout .sales {
  font-size: 24px;
  font-weight: 700;
  padding: 0;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1;
}
.custom-number {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.1;
  color: black;
}

.dark-layout .custom-number {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.1;
  color: rgba(255, 255, 255, 0.8);
}

td {
  border-bottom: 1px rgb(128, 128, 128, 0.5) solid;
  padding: 4px;
}
</style>

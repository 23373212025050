<template>
  <div style="min-height:55vh;">
    <div class="d-flex flex-wrap p-half">
      <dx-util-button text="Sales" />
      <dx-util-button v-if="false" text="Expenses" />
      <dx-util-button v-if="false" text="Returns" />
      <dx-util-button v-if="false" text="Refunds" />
      <div class="flex-grow-1"></div>
      <div v-if="false">
        <dx-util-button v-if="showDay" text="Day" />
        <dx-util-button v-if="showWeek" text="Week" />
        <dx-util-button v-if="showMonth" text="Month" />
      </div>
    </div>
    <div class="px-1">
      <DxChart
        id="overview-chart"
        :data-source="dataSource"
        title="Sales Overview"
        default-pane="bottomPane"
        :customize-point="customizeSeriesColors"
      >
        <DxCommonSeriesSettings
          argument-field="title"
          type="stackedbar"
        />
        <DxValueAxis position="left">
          <DxTitle text="Expenses - Sales ($)" />
        </DxValueAxis>

        <DxSeries
          value-field="totalCogs"
          name="COGS"
        />
        <DxSeries
          value-field="amzShippingFee"
          name="Amazon Fee"
        />
        <DxSeries
          value-field="prepFee"
          name="Prep Fee"
        />
        <DxSeries
          type="spline"
          value-field="price"
          name="Sales"
          color="cyan"
        />
        <DxSeries
          pane="topPane"
          axis="netProfit"
          argument-field="title"
          value-field="netProfit"
          name="Net Profit"
          type="bar"
          color="#75C199"
        >
          <DxLabel :visible="true" :format="'$ #,##0.##'" display-format="valueText">
            <DxFont color="#000" />
          </DxLabel>
        </DxSeries>
        <DxValueAxis name="netProfit" pane="topPane">
          <DxConstantLine
            :width="2"
            :value="0"
            color="#F5564A"
            dash-style="dot"
          >
            <DxLabel text="Profit-Loss Line">
              <DxFont color="#F5564A" />
            </DxLabel>
          </DxConstantLine>
          <DxTitle text="Net Profit">
            <DxFont color="#75C199" />
          </DxTitle>
          <DxLabel :customize-text="customizeText">
            <DxFont color="#75C199" />
          </DxLabel>
        </DxValueAxis>
        <DxLegend
          vertical-alignment="bottom"
          horizontal-alignment="center"
          item-text-position="top"
        />
        <DxExport :enabled="true" />
        <DxTooltip
          :enabled="true"
          :customize-tooltip="customizeTooltip"
          location="edge"
        />
        <DxPane name="topPane" />
        <DxPane name="bottomPane" />
      </DxChart>
    </div>
  </div>
</template>

<script>
import {
  DxChart,
  DxSeries,
  DxCommonSeriesSettings,
  DxValueAxis,
  DxTitle,
  DxConstantLine,

  DxLegend,
  DxExport,
  DxTooltip, DxFont, DxLabel, DxPane,
} from 'devextreme-vue/chart'
import amazonReportService from '@/http/requests/reports/amazonReportService'
import AmazonSalesFilter from '@/http/models/search-filters/AmazonSalesFilter'

export default {
  components: {
    DxPane,
    DxLabel,
    DxFont,
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxConstantLine,
    DxValueAxis,
    DxTitle,
    DxLegend,
    DxExport,
    DxTooltip,
  },
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      filter: {
        beginDate: '',
        endDate: '',
      },
      showDay: true,
      showWeek: true,
      showMonth: true,
      minValue: -5000,
      maxValue: 5000,
      dataSource: [],
    }
  },
  watch: {
    filters: {
      immediate: true,
      deep: true,
      handler() {
        this.getSalesChartData()
      },
    },
  },
  mounted() {
    // this.getSalesChartData()
  },
  methods: {
    async getSalesChartData() {
      const filter = AmazonSalesFilter.getFilters()
      const response = await amazonReportService.dashboardSales(filter)
      this.dataSource = response
    },
    onDateSelection(e) {
      this.filter.beginDate = e.beginDate
      this.filter.endDate = e.endDate
      this.searchByFilter()
    },
    searchByFilter() {
    },
    // datesChanged(value) {
    //   switch (value) {
    //     case 'today':
    //       this.showDay = true
    //       this.showWeek = false
    //       this.showMonth = false
    //       break
    //     case 'current_week':
    //       this.showDay = true
    //       this.showWeek = false
    //       this.showMonth = false
    //       break
    //     case 'current_month':
    //       this.showDay = true
    //       this.showWeek = true
    //       this.showMonth = false
    //       break
    //     case 'current_quarter':
    //       this.showDay = true
    //       this.showWeek = true
    //       this.showMonth = true
    //       break
    //     case 'current_year':
    //       this.showDay = true
    //       this.showWeek = true
    //       this.showMonth = true
    //       break
    //     case 'last7Days':
    //       this.showDay = true
    //       this.showWeek = false
    //       this.showMonth = false
    //       break
    //     case 'last30Days':
    //       this.showDay = true
    //       this.showWeek = true
    //       this.showMonth = false
    //       break
    //     case 'last90Days':
    //       this.showDay = true
    //       this.showWeek = true
    //       this.showMonth = true
    //       break
    //     case 'last365Days':
    //       this.showDay = true
    //       this.showWeek = true
    //       this.showMonth = true
    //       break
    //     default:
    //       break
    //   }
    // },
    customizeTooltip({ seriesName, valueText }) {
      return { text: `${seriesName} Amount: ${valueText}` }
    },
    customizeText({ valueText }) {
      return `${valueText}`
    },
    customizeTopPanelLabel({ valueText }) {
      return `${valueText}`
    },
    customizeSeriesColors({ seriesName, value }) {
      if (seriesName === 'Net Profit') {
        if (value < 0) {
          return { color: '#ff7c7c', hoverStyle: { color: '#ff7c7c' } }
        }
      }
      return ''
    },

  },
}
</script>

<style lang="scss" scoped>
#overview-chart {
  height: 52vh !important;
}
</style>

<template>
  <div style="padding-bottom: 60px ">
    <dx-data-grid
      :data-source="ProductListDataSource"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :column-auto-width="true"
      :show-column-lines="true"
      :default-sort-by="{ fieldName: 'quantity', direction: 'asc' }"
      :show-row-lines="true"
      :show-borders="true"
      :row-alternation-enabled="false"
      :hover-state-enabled="true"
      :customize-columns="customizeColumns"
      @initialized="onInitialized"
      @content-ready="onContentReady"
    >
      <dx-remote-operations :paging="true" :sorting="false" :filtering="false" />
      <dx-paging :page-size="pagerOptions.pageSize" />
      <dx-pager
        :allowed-page-sizes="pagerOptions.allowedPageSizes"
        :show-info="pagerOptions.showInfo"
        :show-navigation-buttons="pagerOptions.showNavigationButtons"
        :show-page-size-selector="pagerOptions.showPageSizeSelector"
        :visible="pagerOptions.visible"
      />
      <dx-column data-field="title" cell-template="titleCellTemplate" />
      <template #titleCellTemplate="{data}">
        <item-info
          :id="data.data.productId"
          :title="data.data.title"
          :asin="data.data.asin"
          :upc="data.data.upc"
          :msku="'hide'"
          :image-url="data.data.smallImage"
          :large-image-url="data.data.largeImage"
          :rank="data.data.amzRank"
          :is-printable="false"
          :is-truncated="false"
          :item-info-visible="false"
        />
      </template>
      <template #quantityTemplate="{data}">
        <div :id="`qty-${data.data.id}`">
          <span :class="`${!data.value || data.value === 0 ? '' : 'badge badge-light-success'}`"> {{ data.value }} </span>
        </div>
      </template>
      <dx-column data-field="inboundQty" caption="WH Inbound" :width="120" cell-template="quantityTemplate" />
      <dx-column data-field="warehouseQty" caption="WH Inventory" :width="120" cell-template="quantityTemplate" />
      <dx-column data-field="amazonQty" caption="AMZ Inventory" :width="120" cell-template="quantityTemplate" />
      <dx-column data-field="itemCount" caption="Sold Qty" :width="100" />
      <dx-column data-field="totalSales" caption="Order Price" :width="110" :format="{ type: 'currency', precision: 2 }" />
      <dx-column data-field="totalCOGS" caption="Buy Cost" :width="110" cell-template="buyCostTemplate" />
      <template #buyCostTemplate="{data}">
        <div>
          <span v-if="data.value > 0" class="text-danger">
            (-{{ getCurrencyFormat(data.value) }})
          </span>
          <span v-else class="text-secondary">
            {{ getCurrencyFormat(data.value) }}
          </span>
        </div>
      </template>
      <dx-column data-field="totalCosts" caption="Total Cost" :width="110" cell-template="totalCostTemplate" />
      <template #totalCostTemplate="{data}">
        <div>
          <a :id="`cost-${data.data.id}`" href="#" class="link text-primary" @click="togglePopover(data, 'totalCosts')">
            <span class="underline_txt">
              {{ getCurrencyFormat(data.value) }}
            </span>
          </a>
        </div>
      </template>
      <dx-column data-field="otherCosts" caption="Other Cost" :width="110" cell-template="otherCostTemplate" />
      <template #otherCostTemplate="{data}">
        <div>
          <a :id="`cost-${data.data.id}`" href="#" class="link text-primary" @click="togglePopover(data, 'otherCosts')">
            <span class="underline_txt">
              {{ getCurrencyFormat(data.value) }}
            </span>
          </a>
        </div>
      </template>
      <dx-column caption="Gross Income" alignment="center">
        <dx-column data-field="profit" :width="140" cell-template="profitTemplate" />
        <dx-column data-field="profitPercent" caption="Profit %" :width="140" cell-template="roiTemplate" />
        <dx-column data-field="roi" caption="ROI %" :width="90" alignment="right" cell-template="roiTemplate" />
      </dx-column>
      <dx-column caption="Net Income" alignment="center">
        <dx-column data-field="netProfit" :width="140" cell-template="profitTemplate" />
        <dx-column data-field="netProfitPercent" caption="Net Profit %" :width="140" cell-template="roiTemplate" />
        <dx-column data-field="netRoi" caption="Net ROI %" :width="90" alignment="right" cell-template="roiTemplate" />
      </dx-column>
      <template #roiTemplate="{ data }">
        <div :class="resolveByValue(data.value)">
          {{ data.value }}%
        </div>
      </template>
      <template #profitTemplate="{data}">
        <div :class="resolveByValue(data.value)">
          {{ getCurrencyFormat(data.value) }}
        </div>
      </template>
    </dx-data-grid>
  </div>
</template>

<script>
import ItemInfo from '@/views/apps/outbound/fba-shipments/components/ItemInfo.vue'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import Filters from '@robustshell/mixins/filters'
import AmazonReportFilter from '@/http/models/search-filters/AmazonReportFilter'
import reportTypeEnum from '@/enums/reportTypeEnum'
import moment from 'moment'
import { currencyFormatter } from '@core/utils/filter'
import { ProductListDataSource } from './productListStore'

export default {
  components: {
    ItemInfo,
  },
  mixins: [GridBase, Filters],
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
    datePeriod: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      ProductListDataSource,
    }
  },
  computed: {
    bd() {
      return moment(this.datePeriod.beginDate).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]').toString()
    },
    ed() {
      return moment(this.datePeriod.endDate).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]').toString()
    },
  },
  watch: {
    filters: {
      immediate: true,
      deep: true,
      handler() {
        this.searchByFilter()
      },
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      AmazonReportFilter.setDefaultFilters()
      AmazonReportFilter.reportType = reportTypeEnum.ASIN.value
      AmazonReportFilter.storeId = this.filters.storeId
      AmazonReportFilter.beginDate = this.bd
      AmazonReportFilter.endDate = this.ed
      const filters = AmazonReportFilter.getFilters()
      ProductListDataSource.searchValue(filters)
    },
    searchByFilter() {
      AmazonReportFilter.setDefaultFilters()
      AmazonReportFilter.reportType = reportTypeEnum.ASIN.value
      AmazonReportFilter.storeId = this.filters.storeId
      AmazonReportFilter.beginDate = this.bd
      AmazonReportFilter.endDate = this.ed
      const filters = AmazonReportFilter.getFilters()
      ProductListDataSource.searchValue(filters)
      ProductListDataSource.reload()
    },
    resolveByValue(value) {
      const profit = parseInt(value, 10)
      if (profit >= 0) {
        return 'badge badge-light-success'
      }
      return 'badge badge-light-danger'
    },
    getCurrencyFormat(value) {
      return currencyFormatter(value)
    },
    onOptionChanged(e) {
      if (e.fullName === 'paging.pageSize') {
        this.dataSource.pageIndex(0)
      }
    },
    onCellDblClick(e) {
      if (e.rowType === 'data' && e.column.dataField === 'smallImage') {
        if (e.eventType === 'dxdblclick') {
          if (e.data.largeImage) {
            this.images.length = 0
            this.index = 0
            this.images.push(e.data.largeImage)
          }
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
